import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetProjectById } from "../../redux/slices/projectSlice";
import {
  AiOutlineUser,
  AiOutlineCalendar,
  AiOutlineHome,
} from "react-icons/ai";
import { projects } from "../../utils/projects/projectConstants";
import { handleApiError } from "../../utils/errorHandler";

const ProjectDetails = () => {
  const { id } = useParams();

  const { project } = useSelector((state) => state.projects);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(handleGetProjectById(id))
        .unwrap()
        .catch((err) => {
          handleApiError(err, dispatch, navigate);
        });
    }
  }, [id, dispatch]);

  return (
    <>
      {project && Object.keys(project).length > 0 && (
        <div className="p-8 bg-gray-100 min-h-screen flex flex-col items-center">
          {/* Project Title */}
          <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="relative">
              <img
                src={
                  project?.project_image_url || projects[project.id % 6].image
                }
                alt={project.name}
                className="w-full h-64 object-cover"
              />
              <h1 className="absolute bottom-4 left-4 bg-white bg-opacity-75 px-4 py-2 rounded-md text-2xl font-semibold text-gray-800">
                {project.name}
              </h1>
            </div>

            {/* Project Details */}
            <div className="p-6 space-y-4">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-gray-800">
                  Project Type:{" "}
                  <span className="font-normal">{project.project_type}</span>
                </h2>
                <p className="text-sm text-gray-500">
                  <AiOutlineCalendar className="inline-block mr-1" />
                  Created on:{" "}
                  {new Date(project.created_at).toLocaleDateString()}
                </p>
              </div>

              <p className="text-gray-600">
                {project.description || "No description available."}
              </p>

              {/* Client Info */}
              <div className="border-t pt-4">
                <h3 className="text-lg font-medium text-gray-700">
                  Client Information
                </h3>
                <div className="mt-2 flex items-center space-x-4">
                  <AiOutlineUser className="text-xl text-gray-600" />
                  <span className="text-gray-800">
                    {project.client_name || "No client name available"}
                  </span>
                </div>
                {project.client_information && (
                  <p className="mt-2 text-gray-600">
                    {project.client_information}
                  </p>
                )}
              </div>

              {/* Address Section */}
              <div className="border-t pt-4">
                <h3 className="text-lg font-medium text-gray-700">Address</h3>
                <div className="flex items-center space-x-4 mt-2">
                  <AiOutlineHome className="text-xl text-gray-600" />
                  <span className="text-gray-800">
                    {project.address || "No address available"}
                  </span>
                </div>
              </div>
            </div>

            {/* Project Files Section */}
            {project.project_files_urls &&
              project.project_files_urls.length > 0 && (
                <div className="p-6 border-t">
                  <h3 className="text-lg font-medium text-gray-700 mb-4">
                    Project Files
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {project.project_files_urls.map((file, index) => (
                      <div
                        key={index}
                        className="border rounded-lg p-4 bg-gray-50 shadow-sm"
                      >
                        <iframe
                          name="projectFile"
                          src={file}
                          title="Contract generated by AI"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

            {/* Footer with Project ID */}
            <div className="bg-gray-50 p-4 text-center">
              <p className="text-gray-500 text-sm">Project ID: {project.id}</p>
            </div>

            {/* Go Back Button */}
            <div className="flex justify-center mt-4">
              <button
                onClick={() => navigate("/")}
                className="text-black bg-white py-2 px-4 rounded-md"
              >
                Go Back to Home
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
