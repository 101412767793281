import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";
import Login from "../pages/authentication/login";
import Register from "../pages/authentication/register";
import ResetPassword from "../pages/authentication/resetPassword";
import NewPassword from "../pages/authentication/newPassword";
import VerifyEmail from "../pages/authentication/verifyEmail";
import Home from "../pages/home";
import Plans from "../pages/subscription/plans";
import NewProject from "../pages/projects/newProject";
import ProjectDetails from "../pages/projects/projectDetails";
import UserManagement from "../pages/admin/userManagement";

const AppRouter = () => {
  const PUBLIC_ROUTES = [
    { path: "/signin", component: <Login /> },
    { path: "/signup", component: <Register /> },
    { path: "/reset-password", component: <ResetPassword /> },
    { path: "/verify-email", component: <VerifyEmail /> },
    { path: "/new-password", component: <NewPassword /> },
  ];

  const PRIVATE_ROUTES = [
    { path: "/", component: Home },
    { path: "/plans", component: Plans },
    // { path: "/", component: Home },
    { path: "/user-management", component: UserManagement },
    { path: "/projects/new", component: NewProject },
    { path: "/projects/:id", component: ProjectDetails },
  ];

  const ADMIN_ROUTES = [
    // { path: "/", component: AdminDashboardOverview },
  ];

  return (
    <Routes>
      {PUBLIC_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PublicRoute>{r.component}</PublicRoute>}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {PRIVATE_ROUTES.map((r, i) => (
        <Route
          path={r.path}
          element={<PrivateRoute component={r.component} />}
          key={`${r.path}-${i + 1}`}
        />
      ))}
      {ADMIN_ROUTES.map((r, i) => (
        <Route
          path={`/admin${r.path}`}
          element={<AdminRoute component={r.component} />}
          key={`${r.path}-${i + 1}`}
        />
      ))}
    </Routes>
  );
};

export default AppRouter;
