import api from "../../api/api";
import { TABLE_ROWS_PER_PAGE } from "../../utils/mainHelper";

const userManagementService = {
  getUsersByCompany: (page = 1, perPage = TABLE_ROWS_PER_PAGE, all = false) =>
    api.get(
      `/user_management/get_users_by_company?page=${page}&per_page=${perPage}&all=${all}`
    ),
  addUserToCompany: (data) => api.post("/user_management/create_user", data),
  updateCompanyUser: (id, data) =>
    api.put(`/user_management/update_user/${id}`, data),
  deleteUserFromCompany: (id) =>
    api.delete(`/user_management/delete_user/${id}`),
};

export default userManagementService;
