import React, { useState, useEffect } from "react";
import { ActiveIcon, DeleteIcon, EditIcon } from "../../assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { handleGetUsersByCompany } from "../../redux/slices/userManagementSlice";
import UserModal from "./userModal";
import { useNavigate } from "react-router-dom";
import { capitalize, TABLE_ROWS_PER_PAGE } from "../../utils/mainHelper";
import EditUserModal from "./editUserModal";
import DeleteUserModal from "./deleteUserModal";
import { handleApiError } from "../../utils/errorHandler";

const UserManagement = () => {
  const { users_by_company, currentPage, totalPages } = useSelector(
    (state) => state.user_management
  );
  const { user } = useSelector((state) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(handleGetUsersByCompany({ page: 1, perPage: TABLE_ROWS_PER_PAGE }))
      .unwrap()
      .catch((err) => {
        handleApiError(err, dispatch, navigate);
      });
  }, [dispatch]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(
        handleGetUsersByCompany({
          page: currentPage + 1,
          perPage: TABLE_ROWS_PER_PAGE,
        })
      );
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(
        handleGetUsersByCompany({
          page: currentPage - 1,
          perPage: TABLE_ROWS_PER_PAGE,
        })
      );
    }
  };

  return (
    <div className="w-[90%] mx-auto">
      <div className="flex items-center justify-between mb-5">
        <h1 className="font-inter text-[24px] font-bold text-[#18181B]">
          User Management
        </h1>
        <button
          onClick={() => setShowModal(true)} // Show modal on button click
          className="bg-black text-white px-4 py-2 rounded-[10px]"
        >
          Add New User
        </button>
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto overflow-hidden rounded-[6px] border border-[#E4E4E7]">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="font-inter text-[14px] font-normal text-left py-3 px-4 border-b border-[#E4E4E7]">
                Name
              </th>
              <th className="font-inter text-[14px] font-normal text-left py-3 px-4 border-b border-[#E4E4E7]">
                Email
              </th>
              <th className="font-inter text-[14px] font-normal text-left py-3 px-4 border-b border-[#E4E4E7]">
                Status
              </th>
              <th className="font-inter text-[14px] font-normal text-left py-3 px-4 border-b border-[#E4E4E7]">
                Phone Number
              </th>
              <th className="font-inter text-[14px] font-normal text-left py-3 px-4 border-b border-[#E4E4E7]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {users_by_company.length > 0 &&
              users_by_company.map((user) => (
                <tr key={user.id}>
                  <td className="font-inter text-[14px] font-normal py-5 px-4 border-b border-[#E4E4E7]">
                    {user.first_name} {user.last_name}
                  </td>
                  <td className="font-inter text-[14px] font-normal py-5 px-4 border-b border-[#E4E4E7]">
                    {user.email}
                  </td>
                  <td className="font-inter text-[14px] font-normal py-5 px-4 border-b border-[#E4E4E7]">
                    {user.status === "active" ? (
                      <img src={ActiveIcon} alt="Active" />
                    ) : (
                      <span className="text-red-600">
                        {capitalize(user.status)}
                      </span>
                    )}
                  </td>
                  <td className="font-inter text-[14px] font-normal py-5 px-4 border-b border-[#E4E4E7]">
                    {user.phone}
                  </td>
                  <td className="font-inter text-[14px] font-normal py-5 px-4 border-b border-[#E4E4E7]">
                    <div className="flex gap-4">
                      <button onClick={() => handleEditUser(user)}>
                        <img src={EditIcon} alt="EditIcon" />
                      </button>
                      <button onClick={() => handleDeleteUser(user)}>
                        <img src={DeleteIcon} alt="DeleteIcon" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center py-3">
        <p className="font-inter text-[14px] font-normal leading-[20px] text-[#71717A]">
          Page {totalPages === 0 ? 0 : currentPage} of {totalPages}
        </p>

        <div className="flex space-x-3">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages || totalPages === 0}
            className="px-4 py-2 rounded-[6px] text-[14px] border border-[#E4E4E7] opacity-50 text-[#18181B]"
          >
            Next
          </button>
        </div>
      </div>

      {showModal && (
        <UserModal onClose={() => setShowModal(false)} user={user} />
      )}

      {showEditModal && (
        <EditUserModal
          user={selectedUser}
          onClose={() => setShowEditModal(false)}
        />
      )}

      {showDeleteModal && (
        <DeleteUserModal
          onClose={() => setShowDeleteModal(false)}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default UserManagement;
