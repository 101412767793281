import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginImg, LogoImg } from "../../assets/images/images";
import { LoginSchema } from "../../utils/schema";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { login } from "../../redux/slices/authSlice";

const Login = () => {
	const initialValues = {
		email: "",
		password: "",
	};

  const dispatch = useDispatch();
	const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate]);

	const handleLogin = (values, { setSubmitting }) => {
		try {
      dispatch(login({
        data: {
          user: {
            email: values.email,
            password: values.password
          }
        },
        navigate
			}));
		} catch (error) {
      if (error.response && error.response.data) {
        Object.entries(error.response.data).forEach(([key, value]) => {
          showAllNotifications(value, ToastColors.error);
        });
      } else if (error.response) {
        showAllNotifications(error.response.message, ToastColors.error);
      } else {
        showAllNotifications(error.message, ToastColors.error);
      }
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<div
			className="w-full h-[100vh] px-12 py-14"
			style={{ backgroundImage: `url(${LoginImg})`, backgroundSize: "cover" }}
		>
			<div className="w-full">
				<img src={LogoImg} alt="VerixPro" />
			</div>
			<div className="w-full flex justify-center items-center">
				<div className="w-full max-w-[343px] mt-20">
					<Formik
						initialValues={initialValues}
						validationSchema={LoginSchema}
						onSubmit={handleLogin}
					>
						{({ isSubmitting }) => (
							<Form className="w-full">
								<div className="w-full text-center text-2xl font-semibold mb-12">
									Sign into your account
								</div>
								<div className="w-full mb-4">
									<label
										className="w-full text-sm font-medium mb-2"
										htmlFor="email"
									>
										Email <span className="text-red-500">*</span>
									</label>
									<Field
										className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
										id="email"
										name="email"
										type="email"
										placeholder="Enter your email"
									/>
									<ErrorMessage
										name="email"
										component="p"
										className="text-red-500 text-xs italic"
									/>
								</div>
								<div className="w-full mb-4">
									<label
										className="w-full text-sm font-medium mb-2"
										htmlFor="password"
									>
										Password <span className="text-red-500">*</span>
									</label>
									<Field
										className="w-full px-3 py-2 rounded-md border border-[1px_solid_#E4E4E7] outline-none text-sm h-9"
										id="password"
										name="password"
										type="password"
										placeholder="Enter your password"
									/>
									<ErrorMessage
										name="password"
										component="p"
										className="text-red-500 text-xs italic"
									/>
								</div>
								<div className="w-full flex justify-between items-center mb-4 text-sm">
									<div className="flex items-center">
										<input type="checkbox" className="mr-1" id="remember" />
										<label
											htmlFor="remember"
											className="cursor-pointer select-none"
										>
											Save Password
										</label>
									</div>
									<Link to="/reset-password">Forgot Password?</Link>
								</div>
								<button
									type="submit"
									className="w-full mb-2 bg-black text-white text-sm h-10 flex justify-center items-center rounded-md"
                  disabled={isSubmitting}
								>
									Sign In
								</button>
								<div className="w-full text-center">
									<Link to="/signup">
										Don't have an account?{" "}
										<span className="font-bold">Sign up</span>
									</Link>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default Login;
