import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import Loader from "./components/Loader";
import "./App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  const { isAuthenticated, isSubscribed } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated && !isSubscribed) navigate("/plans");
  }, [isAuthenticated, isSubscribed, navigate]);

  return (
    <div className="min-h-screen">
      <AppRouter />
      <Loader />
    </div>
  );
}

export default App;
