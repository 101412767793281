import api from "../../api/api";

const authService = {
  register: (data) => api.post("/users", data),
  login: (data) => api.post("/users/sign_in", data),
  logout: () => api.delete("/users/sign_out"),
  resetPassword: (data) => api.post("/users/password", data),
  newPassword: (data) => api.put("/users/password", data),
  verifyEmail: (data) => api.post("/users/verify_otp", data),
  resendOtp: (email) => api.post("/users/resend_otp", { email }),
  refreshToken: (refresh_token) => api.post("/users/refresh_token", { refresh_token }),
};

export default authService;
