import React from "react";
import { useFormik } from "formik";
import { CrossIcon } from "../../assets/icons/icons";
import { EditUserSchema } from "../../utils/schema"; // Assuming you want the same schema for editing
import { useDispatch } from "react-redux";
import { handleUpdateCompanyUser } from "../../redux/slices/userManagementSlice";
import { handleApiError } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const EditUserModal = ({ onClose, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: user.first_name || "",
      lastName: user.last_name || "",
      email: user.email || "",
      phone: user.phone || "",
      role: user.roles[0] || "",
      status: user.status || "",
    },
    validationSchema: EditUserSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          role: values.role,
          status: values.status,
        },
      };

      dispatch(handleUpdateCompanyUser({ id: user.id, data }))
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch((error) => {
          handleApiError(error, dispatch, navigate);
        });
    },
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[500px] p-6 rounded-lg shadow-lg relative">
        <button onClick={onClose} className="absolute top-4 right-4">
          <img src={CrossIcon} alt="Close" />
        </button>

        <h2 className="text-[18px] font-[600] leading-[30px] text-[#18181B] mb-4">
          Edit User
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter first name"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 text-sm">
                {formik.errors.firstName}
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter last name"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 text-sm">
                {formik.errors.lastName}
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Phone Number <span className="text-red-500">*</span>
            </label>
            <input
              id="phone"
              name="phone"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter phone number"
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm">{formik.errors.phone}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="status"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Status <span className="text-red-500">*</span>
            </label>
            <select
              id="status"
              name="status"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md text-[#18181B]"
            >
              <option value="" disabled>
                Status
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            {formik.touched.status && formik.errors.status && (
              <div className="text-red-500 text-sm">{formik.errors.status}</div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="role"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              User Role <span className="text-red-500">*</span>
            </label>
            <select
              id="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md text-[#18181B]"
            >
              <option value="" disabled>
                Select user role
              </option>
              <option value="Administrator">Admin</option>
              <option value="Editor">Editor</option>
              <option value="Estimator">Estimator</option>
              <option value="AccountManager">Account Manager</option>
            </select>
            {formik.touched.role && formik.errors.role && (
              <div className="text-red-500 text-sm">{formik.errors.role}</div>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-black text-white px-6 py-2 rounded-md mt-2 mb-5"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
