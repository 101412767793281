import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Logo } from "../assets/icons/icons";
import { DefaultProfileImg } from "../assets/images/images";
import { logout } from "../redux/slices/authSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, userRoles } = useSelector((state) => state.auth);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuItemClick = (url) => {
    setShowMenu(false);
    navigate(url);
    window.scrollTo(0, 0);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <div className="pb-6">
      <header className="w-full bg-white border-b border-b-[1px_solid_#E4E4E7]">
        <div className="flex items-center justify-between p-4 md:px-12">
          {/* Left Side: Logo and Links */}
          <div className="flex items-center space-x-8">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>

            {/* Desktop Links */}
            <nav className="hidden md:flex space-x-6 text-[16px] text-base text-[#71717A]">
              <Link to="/" className="hover:text-[#18181B]">
                Overview
              </Link>
              <Link to="#" className="hover:text-[#18181B]">
                Data
              </Link>
              <Link to="#" className="hover:text-[#18181B]">
                PDF Editing
              </Link>
              <Link to="#" className="hover:text-[#18181B]">
                Settings
              </Link>
              <Link to="#" className="hover:text-[#18181B]">
                Help
              </Link>
              {userRoles.includes("Administrator") && (
                <Link to="/user-management" className="hover:text-[#18181B]">
                  User Management
                </Link>
              )}
            </nav>
          </div>

          {/* Right Side: Search Bar and Profile Picture */}
          <div className="flex items-center space-x-2">
            {/* Search Bar */}
            <input
              type="text"
              placeholder="Search..."
              className="w-[200px] md:w-[300px] pl-2 py-[5px] border border-[#E4E4E7] rounded-[6px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.1)] font-inter text-[14px] font-normal text-[#18181B] focus:outline-none"
            />

            {/* Logged In User dropdown */}
            <Menu
              as="div"
              className="hidden md:block w-9 h-9 rounded-full overflow-hidden"
            >
              <MenuButton onClick={() => setShowMenu(!showMenu)}>
                <img
                  className="rounded-full w-9 h-9"
                  src={user?.profile_picture || DefaultProfileImg}
                  alt="img"
                />
              </MenuButton>
              <MenuItems
                transition
                className="grid w-40 absolute right-10 z-10 px-0 whitespace-nowrap rounded-lg !bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <div className="w-full px-3 whitespace-nowrap border-b border-b-[1px_solid_#E4E4E7]">
                    <button className="w-full !flex items-center text-black no-underline text-sm text-start font-semibold !border-none !px-0 py-2 !bg-transparent">
                      {user?.first_name} {user?.last_name}
                    </button>
                  </div>
                </MenuItem>
                {userRoles.includes("Administrator") && (
                  <MenuItem>
                    <div className="w-full px-3 whitespace-nowrap border-b border-b-[1px_solid_#E4E4E7] hover:bg-[#E4E4E7]">
                      <button
                        onClick={() => handleMenuItemClick("/dashboard")}
                        className="w-full flex items-center text-black no-underline py-2"
                      >
                        Dashboard
                      </button>
                    </div>
                  </MenuItem>
                )}
                <MenuItem>
                  <div className="w-full px-3 whitespace-nowrap hover:bg-[#E4E4E7]">
                    <button
                      className="w-full !flex items-center text-black no-underline text-sm text-start !border-none !px-0 py-2 !bg-transparent"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </MenuItem>
              </MenuItems>
            </Menu>

            {/* Mobile Menu button */}
            <button
              className="md:hidden text-[#71717A] focus:outline-none"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              <FaBars size={24} />
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <nav className="md:hidden bg-white border-t border-gray-200 px-4 pt-4 pb-6 space-y-4">
            <Link to="/profile" className="block text-[#71717A]">
              Profile
            </Link>
            <Link to="/overview" className="block text-[#71717A]">
              Overview
            </Link>
            <Link to="/data" className="block text-[#71717A]">
              Data
            </Link>
            <Link to="/pdf-editing" className="block text-[#71717A]">
              PDF Editing
            </Link>
            <Link to="/settings" className="block text-[#71717A]">
              Settings
            </Link>
            <Link to="/help" className="block text-[#71717A]">
              Help
            </Link>
          </nav>
        )}
      </header>
    </div>
  );
};

export default Header;
