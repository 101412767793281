export const projectTypes = [
  "Residential Construction",
  "Commercial Construction",
  "Industrial Construction",
  "Infrastructure",
  "Renovation",
  "Demolition",
  "Environmental Remediation",
  "Land Development",
  "Interior Design",
];

export const projects = [
  {
    id: 1,
    name: "Project 1",
    image:
      "https://images.unsplash.com/photo-1448630360428-65456885c650?q=80&w=3267&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Placeholder image paths
    description1: "This is the first project.",
    description2: "Opened 30 mintutes ago",
  },
  {
    id: 2,
    name: "Project 2",
    image:
      "https://images.unsplash.com/photo-1522050212171-61b01dd24579?q=80&w=3437&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description1: "This is the second project.",
    description2: "Opened 30 mintutes ago",
  },
  {
    id: 3,
    name: "Project 3",
    image:
      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fHw%3D",
    description1: "This is the third project.",
    description2: "Opened 30 mintutes ago",
  },
  {
    id: 4,
    name: "Project 4",
    image:
      "https://images.unsplash.com/photo-1605146769289-440113cc3d00?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    description1: "This is the fourth project.",
    description2: "Opened 30 mintutes ago",
  },
  {
    id: 5,
    name: "Project 5",
    image:
      "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8",
    description1: "This is the third project.",
    description2: "Opened 30 mintutes ago",
  },
  {
    id: 6,
    name: "Project 6",
    image:
      "https://plus.unsplash.com/premium_photo-1689609950112-d66095626efb?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE2fHx8ZW58MHx8fHx8",
    description1: "This is the fourth project.",
    description2: "Opened 30 mintutes ago",
  },
  // Add more projects as needed
];
