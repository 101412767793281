import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import userManagementService from "../services/userManagementService";

export const handleGetUsersByCompany = createAsyncThunk(
  "projects/handleGetUsersByCompany",
  async ({ page, perPage, all = false }, thunkAPI) => {
    try {
      const response = await userManagementService.getUsersByCompany(
        page,
        perPage,
        all
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleAddUserToCompany = createAsyncThunk(
  "projects/handleAddUserToCompany",
  async (data, thunkAPI) => {
    try {
      const response = await userManagementService.addUserToCompany(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleUpdateCompanyUser = createAsyncThunk(
  "projects/handleUpdateCompanyUser",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await userManagementService.updateCompanyUser(id, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleDeleteUserFromCompany = createAsyncThunk(
  "projects/handleDeleteUserFromCompany",
  async (id, thunkAPI) => {
    try {
      const response = await userManagementService.deleteUserFromCompany(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const userManagementSlice = createSlice({
  name: "user_management",
  initialState: {
    users_by_company: [],
    status: STATUSES.IDLE,
    error: null,
    loading: false,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {
    addUser: (state, action) => {
      state.users_by_company.unshift(action.payload.user);
    },
    updateUser: (state, action) => {
      const index = state.users_by_company.findIndex(
        (user) => user.id === action.payload.user.id
      );
      if (index !== -1) {
        state.users_by_company[index] = action.payload.user;
      }
    },
    deleteUser: (state, action) => {
      state.users_by_company = state.users_by_company.filter(
        (user) => user.id !== action.payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleGetUsersByCompany.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetUsersByCompany.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.users_by_company = action.payload.users;
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(handleGetUsersByCompany.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
      })
      .addCase(handleAddUserToCompany.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleAddUserToCompany.fulfilled, (state, action) => {
        if (state.users_by_company.length < 5) {
          userManagementSlice.caseReducers.addUser(state, action);
        }
        showAllNotifications(action.payload.message, ToastColors.success);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleAddUserToCompany.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        showAllNotifications(action.payload.error, ToastColors.success);
      })
      .addCase(handleUpdateCompanyUser.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleUpdateCompanyUser.fulfilled, (state, action) => {
        userManagementSlice.caseReducers.updateUser(state, action);
        showAllNotifications(action.payload.message, ToastColors.success);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleUpdateCompanyUser.rejected, (state, action) => {
        showAllNotifications(action.payload.error, ToastColors.success);
        state.status = STATUSES.ERROR;
        state.loading = false;
      })
      .addCase(handleDeleteUserFromCompany.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleDeleteUserFromCompany.fulfilled, (state, action) => {
        userManagementSlice.caseReducers.deleteUser(state, action);
        showAllNotifications(action.payload.message, ToastColors.success);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleDeleteUserFromCompany.rejected, (state, action) => {
        showAllNotifications(action.payload.error, ToastColors.success);
        state.status = STATUSES.ERROR;
        state.loading = false;
      });
  },
});

export default userManagementSlice.reducer;
