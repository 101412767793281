import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createItem, createUnitMeasurement, createUnitOfAcreage, getPreferences } from "../redux/slices/preferenceSlice";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const customStyles = {
  control: (provided) => ({
    ...provided,
    paddingLeft: '8px',
    borderRadius: '6px',
    border: '1px solid #E4E4E7',
    boxShadow: '0px 1px 2px 0px #1018280D',
    fontSize: "14px",
    color: "#71717A",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '6px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const mapCenter = { lat: 37.7749, lng: -122.4194 };

const mapContainerStyle = {
  width: '100%',
  height: '200px',
};

const PreferenceModal = ({ isOpen, onClose, formik, onApply }) => {
  const dispatch = useDispatch();

  const { items, unitMeasurements, unitOfAcreage } = useSelector((state) => state.preferences);

  const [hasLocation, setHasLocation] = useState(false);

  useEffect(() => {
    dispatch(getPreferences());
  }, [dispatch]);

  if (!isOpen) return null;

  const handleCreateItem = (value) => {
    dispatch(createItem({ name: value }));
  };

  const handleCreateUnitMeasurement = (value) => {
    dispatch(createUnitMeasurement({ name: value }));
  };

  const handleCreateUnitOfAcreage = (value) => {
    dispatch(createUnitOfAcreage({ name: value }));
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    formik.setFieldValue("preference.latitude", lat);
    formik.setFieldValue("preference.longitude", lng);
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#18181B99] flex justify-center overflow-y-scroll">
      <div className="relative w-full h-fit max-w-md bg-white p-6 text-[#18181B] flex flex-col justify-center items-center rounded-xl my-10">
        <button
          onClick={onClose}
          className="absolute top-3 right-4 text-gray-400 hover:text-black outline-none focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
        </button>
        <div className="w-full text-lg font-semibold">Preference settings</div>
        <div className="w-full mt-6">
          <label className="w-full text-sm font-medium">Items of interest to quantity *</label>
          <CreatableSelect
            key={`item-${items.length}`}
            name="preference.items"
            className="w-full mt-1"
            options={items.map((opt) => ({ label: opt, value: opt }))}
            defaultValue={formik.values.preference.items.map((opt) => ({ label: opt, value: opt }))}
            value={formik.values.preference.items.map((opt) => ({ label: opt, value: opt }))}
            placeholder="Select items"
            onChange={(value) => formik.setFieldValue("preference.items", value ? value.map((v) => v.value) : [])}
            onBlur={formik.handleBlur}
            styles={customStyles}
            onCreateOption={handleCreateItem}
            isClearable
            isMulti
          />
        </div>
        <div className="w-full mt-6">
          <label className="w-full text-sm font-medium">Unit Measurement *</label>
          <CreatableSelect
            key={`unit-measurements-${unitMeasurements.length}`}
            name="preference.unitMeasurements"
            className="w-full mt-1"
            options={unitMeasurements.map((opt) => ({ label: opt, value: opt }))}
            defaultValue={formik.values.preference.unitMeasurements.map((opt) => ({ label: opt, value: opt }))}
            value={formik.values.preference.unitMeasurements.map((opt) => ({ label: opt, value: opt }))}
            placeholder="Select units"
            onChange={(value) => formik.setFieldValue("preference.unitMeasurements", value ? value.map((v) => v.value) : [])}
            onBlur={formik.handleBlur}
            styles={customStyles}
            onCreateOption={handleCreateUnitMeasurement}
            isClearable
            isMulti
          />
        </div>
        <div className="w-full mt-6 flex justify-between items-center">
          <label className="text-sm font-medium">Automated Calculated Percentage</label>
          <label className="inline-flex items-center cursor-pointer">
            <input name="preference.acp" type="checkbox" className="sr-only peer" onChange={() => formik.setFieldValue("preference.acp", !formik.values.preference.acp)} />
            <div className="relative w-11 h-6 bg-[#E2E8F0] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
          </label>
        </div>
        <div className="w-full mt-6 flex justify-between items-center">
          <label className="text-sm font-medium">Acreage</label>
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" />
            <div className="relative w-11 h-6 bg-[#E2E8F0] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
          </label>
        </div>
        <div className="w-full mt-6">
          <label className="w-full text-sm font-medium">Units of Acreage *</label>
          <CreatableSelect
            key={`unit-of-acreage-${unitOfAcreage.length}`}
            name="preference.unitOfAcreage"
            className="w-full mt-1"
            options={unitOfAcreage.map((opt) => ({ label: opt, value: opt }))}
            defaultValue={formik.values.preference.unitOfAcreage.map((opt) => ({ label: opt, value: opt }))}
            value={formik.values.preference.unitOfAcreage.map((opt) => ({ label: opt, value: opt }))}
            placeholder="Select units"
            onChange={(value) => formik.setFieldValue("preference.unitOfAcreage", value ? value.map((v) => v.value) : [])}
            onBlur={formik.handleBlur}
            styles={customStyles}
            onCreateOption={handleCreateUnitOfAcreage}
            isClearable
            isMulti
          />
        </div>
        <div className="w-full mt-6 flex justify-between items-center">
          <label className="text-sm font-medium">Longitude - Latitude</label>
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value={hasLocation} checked={hasLocation} className="sr-only peer" onChange={() => setHasLocation(!hasLocation)} />
            <div className="relative w-11 h-6 bg-[#E2E8F0] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
          </label>
        </div>
        {
          formik.values.preference.latitude && formik.values.preference.longitude && (
            <div className="w-full flex justify-between mt-3">
              <p className="text-xs">Latitude: {formik.values.preference.latitude}</p>
              <p className="text-xs">Longitude: {formik.values.preference.longitude}</p>
            </div>
          )
        }
        {
          hasLocation && (
            <div className="w-full mt-4">
              <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={mapCenter}
                  zoom={10}
                  onClick={handleMapClick}
                >
                  {formik.values.preference.latitude && formik.values.preference.longitude && (
                    <Marker
                      position={{
                        lat: formik.values.preference.latitude,
                        lng: formik.values.preference.longitude,
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          )
        }
        <div className="w-full flex justify-end items-center">
          <button className="h-9 px-6 me-2 bg-[#71717A] hover:bg-[#91919A] text-white text-sm font-medium rounded-md mt-7" onClick={onClose}>Cancel</button>
          <button className="h-9 px-6 bg-black hover:bg-[#444444] text-white text-sm font-medium rounded-md mt-7" onClick={onApply}>Apply</button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceModal;
