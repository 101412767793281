import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import preferenceService from '../services/preferenceService';
import { STATUSES } from '../../constants/statuses';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

export const getPreferences = createAsyncThunk('preference/getPreferences', async (_, thunkAPI) => {
  try {
    const response = await preferenceService.getPreferences(_);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createItem = createAsyncThunk('preference/createItem', async (data, thunkAPI) => {
  try {
    const response = await preferenceService.createItem(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createUnitMeasurement = createAsyncThunk('preference/createUnitMeasurement', async (data, thunkAPI) => {
  try {
    const response = await preferenceService.createUnitMeasurement(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createUnitOfAcreage = createAsyncThunk('preference/createUnitOfAcreage', async (data, thunkAPI) => {
  try {
    const response = await preferenceService.createUnitOfAcreage(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const preferenceSlice = createSlice({
  name: 'preference',
  initialState: {
    items: [],
    unitMeasurements: [],
    unitOfAcreage: [],
    status: STATUSES.IDLE,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreferences.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.items = action.payload.items || [];
        state.unitMeasurements = action.payload.unit_measurements || [];
        state.unitOfAcreage = action.payload.unit_of_acreages || [];
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(getPreferences.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = 'Got an error while loading preference options!';
        showAllNotifications(state.error, ToastColors.error)
      })
      .addCase(createItem.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(createItem.fulfilled, (state, action) => {
        state.items = [...state.items, action.payload.item];
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(createItem.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = 'Got an error while adding new item!';
        showAllNotifications(state.error, ToastColors.error)
      })
      .addCase(createUnitMeasurement.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(createUnitMeasurement.fulfilled, (state, action) => {
        state.unitMeasurements = [...state.unitMeasurements, action.payload.unit_measurement];
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(createUnitMeasurement.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = 'Got an error while adding new unit!';
        showAllNotifications(state.error, ToastColors.error)
      })
      .addCase(createUnitOfAcreage.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(createUnitOfAcreage.fulfilled, (state, action) => {
        state.unitOfAcreage = [...state.unitOfAcreage, action.payload.unit_of_acreage];
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(createUnitOfAcreage.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = 'Got an error while adding new unit!';
        showAllNotifications(state.error, ToastColors.error)
      });
  },
});

export default preferenceSlice.reducer;
