import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import planService from '../services/planService';
import { STATUSES } from '../../constants/statuses';
import { showAllNotifications } from '../../utils/notificationHelper';
import ToastColors from '../../constants/toastColors';

export const createCheckoutSession = createAsyncThunk('plan/createCheckoutSession', async (data, thunkAPI) => {
  try {
    const response = await planService.createCheckoutSession(data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const planSlice = createSlice({
  name: 'plan',
  initialState: {
    sessionId: null,
    status: STATUSES.IDLE,
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.sessionId = action.payload.id;
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(createCheckoutSession.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
        state.error = 'Something went wrong. Please try again later!';
        showAllNotifications(state.error, ToastColors.error)
      });
  },
});

export default planSlice.reducer;
