import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { successCheckmark } from "../../assets/icons/icons";


const SuccessModal = ({ planName, isOpen }) => {
  const onClose = () => {
    window.location.href = window.location.href.split("?")[0];
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-[#18181B99] flex justify-center">
      <div className="relative w-full h-fit max-w-md bg-white p-10 text-center text-[#18181B] flex flex-col justify-center items-center rounded-xl mt-10">
        <button
          onClick={onClose}
          className="absolute top-3 right-4 text-gray-400 hover:text-black outline-none focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
        </button>
        Thank You For Your Purchase
        <img className="mt-9" src={successCheckmark} alt="successCheckmark" />
        <div className="w-full mt-8 max-w-48">
          Plan <span className="font-bold">{planName}</span> has been subscribed.
        </div>
        <button className="h-9 px-6 bg-black hover:bg-[#444444] text-white text-sm font-medium rounded-md mt-7" onClick={onClose}>Back to portal</button>
      </div>
    </div>
  );
};

export default SuccessModal;
