import React, { useRef, useState } from "react";
import { LoginImg, LogoImg } from "../../assets/images/images";
import { mailIcon } from "../../assets/icons/icons";
import authService from "../../redux/services/authService";
import { useLocation, useNavigate } from "react-router-dom";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

const VerifyEmail = () => {
	const inputRefs = useRef([]);
  const navigate = useNavigate();
	const location = useLocation();
	const { email, type } = location.state || {};
  const [otpCode, setOtpCode] = useState("");

	const handleInputChange = (e, index) => {
		const value = e.target.value;
		if (value.length === 1 && index < inputRefs.current.length - 1) {
			inputRefs.current[index + 1].focus();
		}
    setOtpCode(getOtpCode());
	};

	const handleKeyDown = (e, index) => {
		if (e.key === "Backspace" && index > 0 && e.target.value === "") {
			inputRefs.current[index - 1].focus();
		}
    setOtpCode(getOtpCode());
	};

	const handleVerifyOTP = async (e) => {
    e.preventDefault();
		try {
			await authService.verifyEmail({
				email,
				otp_code: otpCode,
			});
			showAllNotifications(
				"Token verified successfully, please login to continue",
				ToastColors.success
			);
			if (type === "forgot_password")
				navigate("/set-new-password", { state: { email: email } });
			else navigate("/signin");
		} catch (error) {
      if (error.response && error.response.data) {
        Object.entries(error.response.data).forEach(([key, value]) => {
          showAllNotifications(value, ToastColors.error);
        });
      } else if (error.response) {
        showAllNotifications(error.response.message, ToastColors.error);
      } else {
        showAllNotifications(error.message, ToastColors.error);
      }
		}
	};

  const resendOTP = async (e) => {
    e.preventDefault();
    try {
      await authService.resendOtp(email);
      showAllNotifications('Token has been resent to your email address', ToastColors.success)
    } catch (error) {
      console.error('error', error)
    }
  }

	const getOtpCode = () => {
		const otpCode = inputRefs.current.map((input) => input.value).join("");
		return otpCode;
	};

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim();

    if (pastedData.length === inputRefs.current.length) {
      pastedData.split("").forEach((char, index) => {
        inputRefs.current[index].value = char;
      });
      setOtpCode(pastedData); // Update OTP code state
    }
  };

	return (
		<div
			className="w-full h-[100vh] px-12 py-14"
			style={{
				backgroundImage: `url(${LoginImg})`,
				backgroundSize: "107%",
				backgroundPositionX: "right",
				backgroundPositionY: "top",
			}}
		>
			<div className="w-full">
				<img src={LogoImg} alt="VerixPro" />
			</div>
			<div className="w-full flex justify-center items-center">
				<div className="w-full max-w-[494px] mt-12 bg-white py-12 px-6 rounded-lg">
					<div className="w-full mb-2 flex justify-center">
						<img src={mailIcon} alt="mail" />
					</div>
					<div className="w-full text-center text-2xl font-semibold pb-6 mb-6 border-b border-b-[1px_solid_#E4E4E7]">
						Verify your email address
					</div>
					<div className="w-full px-6">
						<div className="w-full mb-2 text-[#71717A]">
							A verification code has been sent to
						</div>
						<div className="w-full mb-3 font-medium">{email}</div>
						<div className="w-full mb-4 text-[#71717A]">
							Please check your email and enter the verification code bellow to
							verify your email address. The code will expire in{" "}
							<span className="text-black">12:55</span>
						</div>
						<div className="w-full mb-8 flex justify-between">
							{[...Array(6)].map((_, index) => (
								<input
									key={index}
									type="text"
									maxLength="1"
									ref={(el) => (inputRefs.current[index] = el)}
									className="w-14 h-14 px-5 border border-[1px_solid_#E4E4E7] outline-none font-medium text-center otp-input"
									placeholder="X"
									onChange={(e) => handleInputChange(e, index)}
									onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
								/>
							))}
						</div>
						<button
							type="button"
							className="w-full bg-black text-white text-sm h-10 rounded-md"
							onClick={handleVerifyOTP}
              disabled={otpCode.length < 6}
						>
							Verify
						</button>
						<button
							type="button"
							className="w-full mt-1 bg-transparent text-[#71717A] text-sm h-10"
              onClick={resendOTP}
						>
							Resend code
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyEmail;
