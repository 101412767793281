import React from "react";
import { useFormik } from "formik";
import { CrossIcon } from "../../assets/icons/icons"; // Assuming you have an SVG for closing
import { AddUserSchema } from "../../utils/schema";
import { useDispatch } from "react-redux";
import { handleAddUserToCompany } from "../../redux/slices/userManagementSlice";
import { handleApiError } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const UserModal = ({ onClose, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: AddUserSchema,
    onSubmit: (values) => {
      const data = {
        user: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          company_id: user.company_id,
          role: values.role,
          password: values.password,
          password_confirmation: values.confirmPassword,
        },
      };

      dispatch(handleAddUserToCompany(data))
        .unwrap()
        .then(() => {
          onClose();
        })
        .catch((error) => {
          handleApiError(error, dispatch, navigate);
        });
    },
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[500px] p-6 rounded-lg shadow-lg relative">
        {/* Close Button */}
        <button onClick={onClose} className="absolute top-4 right-4">
          <img src={CrossIcon} alt="Close" />
        </button>

        {/* Modal Heading */}
        <h2 className="text-[18px] font-[600] leading-[30px] text-[#18181B] mb-4">
          Add New User
        </h2>

        {/* Form */}
        <form onSubmit={formik.handleSubmit}>
          {/* First Name */}
          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter first name"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className="text-red-500 text-sm">
                {formik.errors.firstName}
              </div>
            )}
          </div>

          {/* Last Name */}
          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter last name"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 text-sm">
                {formik.errors.lastName}
              </div>
            )}
          </div>

          {/* Email */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            )}
          </div>

          {/* Phone */}
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Phone Number <span className="text-red-500">*</span>
            </label>
            <input
              id="phone"
              name="phone"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter phone number"
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="text-red-500 text-sm">{formik.errors.phone}</div>
            )}
          </div>

          {/* Role */}
          <div className="mb-4">
            <label
              htmlFor="role"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              User Role <span className="text-red-500">*</span>
            </label>
            <select
              id="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md text-[#18181B]"
            >
              <option value="" disabled>
                Select user role
              </option>
              <option value="Administrator">Admin</option>
              <option value="Editor">Editor</option>
              <option value="Estimator">Estimator</option>
              <option value="AccountManager">Account Manager</option>
            </select>
            {formik.touched.role && formik.errors.role && (
              <div className="text-red-500 text-sm">{formik.errors.role}</div>
            )}
          </div>

          {/* Password */}
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Password <span className="text-red-500">*</span>
            </label>
            <input
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Enter password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 text-sm">
                {formik.errors.password}
              </div>
            )}
          </div>

          {/* Confirm Password */}
          <div className="mb-4">
            <label
              htmlFor="confirmPassword"
              className="block text-[14px] font-[500] leading-[20px] text-[#18181B] mb-2"
            >
              Confirm Password <span className="text-red-500">*</span>
            </label>
            <input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className="w-full px-4 py-2 border border-[#E4E4E7] rounded-md placeholder-[#71717A] text-[#18181B]"
              placeholder="Confirm password"
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500 text-sm">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>

          {/* Add User Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-black text-white px-6 py-2 rounded-md mt-2 mb-5"
            >
              Add User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserModal;
