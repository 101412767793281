import api from "../../api/api";

const preferenceService = {
  getPreferences: () => api.get("/preferences"),
  createItem: (data) => api.post("/preferences/create_item", data),
  createUnitMeasurement: (data) => api.post("/preferences/create_unit_measurement", data),
  createUnitOfAcreage: (data) => api.post("/preferences/create_unit_of_acreage", data),
};

export default preferenceService;
