import React from "react";
import { CrossIcon, DeleteUserIcon } from "../../assets/icons/icons";
import { useDispatch } from "react-redux";
import { handleDeleteUserFromCompany } from "../../redux/slices/userManagementSlice";
import { handleApiError } from "../../utils/errorHandler";
import { useNavigate } from "react-router-dom";

const DeleteUserModal = ({ onClose, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteUser = () => {
    dispatch(handleDeleteUserFromCompany(user.id))
      .unwrap()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        handleApiError(error, dispatch, navigate);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 pb-[300px]">
      <div className="bg-white rounded-lg p-6 w-[90%] max-w-md relative">
        <button onClick={onClose} className="absolute top-4 right-4">
          <img src={CrossIcon} alt="Close" />
        </button>

        <h2 className="text-[18px] font-[600] leading-[30px] text-[#18181B] mb-4">
          Delete User
        </h2>

        <div className="flex flex-col items-center justify-center text-center space-y-4">
          <div>
            <img src={DeleteUserIcon} alt="Delete Icon" />
          </div>

          <p className="font-inter text-[14px] font-semibold leading-[20px] text-[#18181B]">
            You are about to delete a user.
          </p>

          <p className="font-inter text-[14px] font-normal leading-[20px]">
            This wil delete user permanently.
          </p>

          <p className="font-inter text-[14px] font-normal leading-[20px] text-center">
            Are you sure?
          </p>
        </div>

        <div className="flex items-center justify-center space-x-4 mt-6">
          <button
            onClick={onClose}
            className="bg-[#71717A] text-white px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteUser}
            className="bg-[#18181B] text-white px-4 py-2 rounded-md"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
