import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { heavyCheckmark, whiteCheckmark } from "../../assets/icons/icons";
import { createCheckoutSession } from "../../redux/slices/planSlice";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Plans = () => {
  const dispatch = useDispatch();
  const { sessionId } = useSelector((state) => state.plans);

  useEffect(() => {
    if (sessionId) {
      const redirectToCheckout = async () => {
        try {
          const stripe = await stripePromise;
          const result = stripe.redirectToCheckout({ sessionId });

          if (result.error) {
            showAllNotifications(result.error.message, ToastColors.error);
          }
        } catch (error) {
          showAllNotifications("Soomething went wrong. Please try again!", ToastColors.error);
        }
      }

      redirectToCheckout();
    }
  }, [sessionId]);

	const handleCheckout = async (planId) => {
    dispatch(createCheckoutSession({ plan_id: planId }));
	};

	return (
		<div className="w-full h-full flex justify-center items-center pt-28">
			<div className="w-full max-w-[1030px]">
				<div className="w-full text-center text-2xl font-bold mb-3">
					Choose your plan
				</div>
				<div className="w-full text-center mb-8">
					14 days unlimited free trial. No contract or credit card required.
				</div>
				<div className="w-full flex justify-center items-center mb-10">
					<div className="flex justify-center items-center gap-5">
						<div className="text-lg font-semibold">Monthly</div>
						<label className="inline-flex items-center cursor-pointer">
							<input type="checkbox" value="" className="sr-only peer" />
							<div className="relative w-11 h-6 bg-black peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-500"></div>
						</label>
						<div className="text-lg font-semibold">Yearly</div>
					</div>
				</div>
				<div className="w-full flex justify-between gap-5">
					<div className="w-full py-5">
						<div className="w-full h-full p-10 bg-[#FAFAFD] border border-[1px_solid_#E4E4E7] rounded-xl shadow-[-4px_4px_30px_0px_#0000000A]">
							<div className="text-lg font-semibold mb-1">Basic</div>
							<div className="text-xs font-semibold text-[#71717A] mb-8">
								Ideal for indiviual
							</div>
							<div className="flex mb-8">
								<div className="text-[40px] font-bold me-1">$4.99</div>
								<div className="grid text-[#71717A] text-xs font-semibold h-fit my-auto">
									<div>per seat</div>
									<div>/month</div>
								</div>
							</div>
							<button
								className="w-full h-11 rounded-md bg-black text-white text-xs font-semibold mb-6 hover:bg-[#71717A]"
								onClick={() => handleCheckout("Basic")}
							>
								Subscribe
							</button>
							<div className="w-full">
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="w-full flex-grow px-10 py-16 bg-black rounded-xl text-white">
						<div className="text-lg font-semibold mb-1">Agency</div>
						<div className="text-xs font-semibold mb-8">Ideal for terms</div>
						<div className="flex mb-8">
							<div className="text-[40px] font-bold me-1">$19.99</div>
							<div className="grid text-xs font-semibold h-fit my-auto">
								<div>per seat</div>
								<div>/month</div>
							</div>
						</div>
						<button
							className="w-full h-11 rounded-md bg-white text-black text-xs font-semibold mb-6 hover:bg-[#71717A] hover:text-white"
							onClick={() => handleCheckout("Agency")}
						>
							Subscribe
						</button>
						<div className="w-full">
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
							<div className="w-full flex items-start justify-start mb-2">
								<img src={whiteCheckmark} alt="heavyCheckmark" />
								<div className="w-full ms-3 text-xs font-semibold">
									Lorem ipsum es to dolor
								</div>
							</div>
						</div>
					</div>

					<div className="w-full py-5">
						<div className="w-full h-full p-10 bg-[#FAFAFD] border border-[1px_solid_#E4E4E7] rounded-xl shadow-[-4px_4px_30px_0px_#0000000A]">
							<div className="text-lg font-semibold mb-1">Enterprise</div>
							<div className="text-xs font-semibold text-[#71717A] mb-5">
								Most comprehensive platform features
							</div>
							<div className="text-lg font-semibold mb-1">
								Contact us for details
							</div>
							<div className="text-xs text-[#71717A]">Contact:</div>
							<div className="text-xs text-[#71717A] mb-8">
								marketverse@thecosmicai.com for details
							</div>
							<div className="w-full">
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
								<div className="w-full flex items-start justify-start mb-2">
									<img src={heavyCheckmark} alt="heavyCheckmark" />
									<div className="w-full ms-3 text-[#71717A] text-xs font-semibold">
										Lorem ipsum es to dolor
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Plans;
