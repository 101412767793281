import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../constants/statuses";
import { showAllNotifications } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import projectService from "../services/projectService";

export const handleCreateProject = createAsyncThunk(
  "projects/handleCreateProject",
  async ({ data, navigate }, thunkAPI) => {
    try {
      const response = await projectService.createProject(data);
      return { ...response.data, navigate };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleGetProjects = createAsyncThunk(
  "projects/handleGetProjects",
  async ({ page, perPage }, thunkAPI) => {
    try {
      const response = await projectService.getProjects(page, perPage);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleGetProjectById = createAsyncThunk(
  "projects/handleGetProjectById",
  async (id, thunkAPI) => {
    try {
      const response = await projectService.getProjectById(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleUpdateProject = createAsyncThunk(
  "projects/handleUpdateProject",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await projectService.updateProject(id, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const handleDeleteProject = createAsyncThunk(
  "projects/handleDeleteProject",
  async (id, thunkAPI) => {
    try {
      const response = await projectService.deleteProject(id);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const projectSlice = createSlice({
  name: "projects",
  initialState: {
    data: [],
    project: {},
    status: STATUSES.IDLE,
    error: null,
    loading: false,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {
    updateProject: (state, action) => {
      const index = state.data.findIndex(
        (project) => project.id === action.payload.id
      );
      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    deleteProject: (state, action) => {
      state.data = state.data.filter(
        (project) => project.id !== action.payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleCreateProject.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleCreateProject.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        action.payload.navigate("/");
        showAllNotifications(
          "Project created successfully!",
          ToastColors.success
        );
      })
      .addCase(handleCreateProject.rejected, (state, action) => {
        let errors;
        if (action.payload?.errors?.length > 0) {
          errors = action.payload.errors;
        } else if (action.payload?.error) {
          errors = action.payload?.error;
        } else {
          errors = "Project could not be created due to an error";
        }
        state.status = STATUSES.ERROR;
        state.loading = false;
        showAllNotifications(errors, ToastColors.error);
      })
      .addCase(handleGetProjects.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetProjects.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
        state.data = action.payload.projects;
        state.currentPage = action.payload.current_page;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(handleGetProjects.rejected, (state) => {
        state.status = STATUSES.ERROR;
        state.loading = false;
      })
      .addCase(handleGetProjectById.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleGetProjectById.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = null;
        state.project = action.payload;
        state.loading = false;
      })
      .addCase(handleGetProjectById.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(handleUpdateProject.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleUpdateProject.fulfilled, (state, action) => {
        projectSlice.caseReducers.updateProject(state, action);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleUpdateProject.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(handleDeleteProject.pending, (state) => {
        state.status = STATUSES.LOADING;
        state.error = null;
        state.loading = true;
      })
      .addCase(handleDeleteProject.fulfilled, (state, action) => {
        projectSlice.caseReducers.deleteProject(state, action);
        state.status = STATUSES.IDLE;
        state.error = null;
        state.loading = false;
      })
      .addCase(handleDeleteProject.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default projectSlice.reducer;
