import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import { NewProjectSchema } from "../../utils/schema";
import {
  CheckCircleGreen,
  PreferenceSetting,
  PreferencesIcon,
  RemoveIcon,
  UploadFileIcon,
} from "../../assets/icons/icons";
import { showNotification } from "../../utils/notificationHelper";
import ToastColors from "../../constants/toastColors";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleCreateProject } from "../../redux/slices/projectSlice";
import { projectTypes } from "../../utils/projects/projectConstants";
import PreferenceModal from "../../components/PreferenceModal";
import { handleGetUsersByCompany } from "../../redux/slices/userManagementSlice";
import { TABLE_ROWS_PER_PAGE } from "../../utils/mainHelper";

const NewProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { users_by_company } = useSelector((state) => state.user_management);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showPreferenceModal, setShowPreferenceModal] = useState(false);

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    formik.setFieldValue("inviteEmails", [
      ...new Set([...formik.values.inviteEmails, ...selectedValues]),
    ]);
  };

  const emailOptions = users_by_company?.map((user) => ({
    label: user.email,
    value: user.email,
  }));

  useEffect(() => {
    dispatch(
      handleGetUsersByCompany({
        page: 1,
        perPage: TABLE_ROWS_PER_PAGE,
        all: true,
      })
    );
  }, [dispatch]);

  const initialValues = {
    projectName: "",
    projectType: "",
    clientName: "",
    projectDescription: "",
    inviteEmails: [],
    inviteEmail: "",
    projectImage: null,
    projectFiles: [],
    createCopy: false,
    preference: {
      items: [],
      unitMeasurements: [],
      unitOfAcreage: [],
      acp: false,
      acreage: false,
      longitude: null,
      latitude: null,
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NewProjectSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("project[name]", values.projectName);
      formData.append("project[project_type]", values.projectType);
      formData.append("project[client_name]", values.clientName);
      formData.append("project[description]", values.projectDescription);
      formData.append("project[user_id]", user.id);

      values.preference.items.forEach((item, index) => {
        formData.append(`project[preference][items][${index}]`, item);
      });

      values.preference.unitMeasurements.forEach((unit, index) => {
        formData.append(
          `project[preference][unit_measurements][${index}]`,
          unit
        );
      });

      values.preference.unitOfAcreage.forEach((unit, index) => {
        formData.append(
          `project[preference][unit_of_acreages][${index}]`,
          unit
        );
      });

      formData.append("project[preference][acp]", values.preference.acp);
      formData.append(
        "project[preference][acreage]",
        values.preference.acreage
      );
      formData.append(
        "project[preference][longitude]",
        values.preference.longitude
      );
      formData.append(
        "project[preference][latitude]",
        values.preference.latitude
      );

      values.inviteEmails.forEach((email, index) => {
        formData.append(`project[invitations][${index}]`, email);
      });

      formData.append("project[project_image]", values.projectImage);

      values.projectFiles.forEach((file) => {
        formData.append("project[project_files][]", file);
      });

      dispatch(
        handleCreateProject({
          data: formData,
          navigate,
        })
      );
    },
  });

  const handleRemoveEmail = (emailToRemove) => {
    const updatedEmails = formik.values.inviteEmails.filter(
      (email) => email !== emailToRemove
    );
    formik.setFieldValue("inviteEmails", updatedEmails);
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    formik.setFieldValue("projectFiles", [
      ...formik.values.projectFiles,
      ...files,
    ]);
    setUploadedFiles([...uploadedFiles, ...files.map((file) => file.name)]);
  };

  const handleApplyPreference = () => {
    if (
      formik.values.preference.items.length > 0 &&
      formik.values.preference.unitMeasurements.length > 0 &&
      formik.values.preference.unitOfAcreage.length > 0
    ) {
      setShowPreferenceModal(false);
    } else {
      showNotification(
        "Please select at least one item, unit measurement and unit of acreage",
        ToastColors.error
      );
    }
  };

  return (
    <div className="w-full p-4 md:px-12 mx-auto mb-8">
      {/* Heading */}
      <h1 className="font-inter text-[24px] font-bold text-[#18181B] mb-4">
        Add new project
      </h1>

      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col lg:flex-row justify-between"
      >
        {/* Left Section */}
        <div className="w-[49%] grid">
          <div className="flex flex-col md:flex-row md:space-x-4">
            {/* Project Name */}
            <div className="w-full md:w-1/2 mb-4">
              <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
                Project Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter project name"
                name="projectName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectName}
                className={`w-full h-9 border rounded-lg px-3 text-[14px] focus:outline-none placeholder:text-[#71717A] ${
                  formik.touched.projectName && formik.errors.projectName
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              {formik.touched.projectName && formik.errors.projectName ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.projectName}
                </div>
              ) : null}
            </div>
            {/* Project Type */}
            <div className="w-full md:w-1/2 mb-4">
              <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
                Project Type <span className="text-red-500">*</span>
              </label>
              <select
                name="projectType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.projectType}
                className={`w-full h-9 border rounded-lg px-3 text-[14px] ${
                  formik.touched.projectType && formik.errors.projectType
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              >
                <option value="">Select Project Type</option>
                {projectTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {formik.touched.projectType && formik.errors.projectType ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.projectType}
                </div>
              ) : null}
            </div>
          </div>

          {/* Client Name */}
          <div className="w-full md:w-[49%]">
            <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
              Client Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="clientName"
              placeholder="Enter client name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.clientName}
              className={`w-full h-9 border rounded-lg px-3 text-[14px] focus:outline-none placeholder:text-[#71717A] ${
                formik.touched.clientName && formik.errors.clientName
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
            {formik.touched.clientName && formik.errors.clientName ? (
              <div className="text-red-500 text-sm">
                {formik.errors.clientName}
              </div>
            ) : null}
          </div>

          {/* Description Textarea */}
          <div className="w-full mt-4">
            <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
              Project Description
            </label>
            <textarea
              name="projectDescription"
              placeholder="Enter details"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.projectDescription}
              className={`w-full h-[65px] border rounded-lg py-2 px-3 text-[14px] resize-none focus:outline-none placeholder:text-[#71717A] ${
                formik.touched.projectDescription &&
                formik.errors.projectDescription
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-4 mt-4">
            {/* First Column: Input with Button */}
            <div className="w-full md:w-1/2 flex">
              <div className="flex-grow mr-2">
                <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
                  Invite Team Members
                </label>
                <ReactSelect
                  isMulti
                  name="inviteEmails"
                  options={emailOptions}
                  value={[]} // Remove selected values from the display
                  onChange={handleChange}
                  isClearable={true} // Allow clearing selections
                  className="w-full"
                  classNamePrefix="select"
                  placeholder="Select emails"
                />
                {formik.touched.inviteEmails && formik.errors.inviteEmails ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.inviteEmails}
                  </div>
                ) : null}
              </div>
            </div>
            {/* Second Column: Project Image Upload */}
            <div className="w-full md:w-1/2">
              <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
                Project Image <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                name="projectImage"
                accept="image/*"
                onChange={(event) => {
                  formik.setFieldValue(
                    "projectImage",
                    event.currentTarget.files[0]
                  );
                }}
                className="w-full h-9 border rounded-lg py-[3px] px-3 text-[14px] border-gray-300"
              />
              {formik.touched.projectImage && formik.errors.projectImage ? (
                <div className="text-red-500 text-sm">
                  {formik.errors.projectImage}
                </div>
              ) : null}
            </div>
          </div>

          {formik.values.inviteEmails.length > 0 && (
            <div className="mt-4">
              <ul>
                {formik.values.inviteEmails.map((email, index) => (
                  <li
                    key={index}
                    className="text-[#71717A] flex items-center py-2 mb-2"
                  >
                    <span>{email}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveEmail(email)}
                    >
                      <img
                        src={RemoveIcon}
                        alt="FilterProjectIcon"
                        className="ml-4"
                      />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="my-4">
            <label className="block mb-1 font-inter text-sm font-medium leading-5 text-[#18181B]">
              Upload File <span className="text-red-500">*</span>
            </label>
            <div
              className="w-full mt-4 p-12 border-dotted border-2 border-gray-300 rounded-xl flex flex-col items-center justify-center text-center !cursor-pointer"
              onClick={() => document.getElementById("fileUpload").click()}
            >
              <img src={UploadFileIcon} alt="UploadFileIcon" className="mb-4" />
              <p className="text-[#18181B] text-[14px]">
                Upload multiple file (Pdf, Doc)
              </p>
              <input
                id="fileUpload"
                type="file"
                multiple
                className="hidden"
                onChange={handleFileUpload}
                onBlur={() => formik.setFieldTouched("projectFiles", true)}
              />

              {/* Display Uploaded Images */}
              <div className="mt-4">
                {uploadedFiles.length > 0 ? (
                  <ul>
                    {uploadedFiles.map((fileName, index) => (
                      <li key={index} className="text-[#18181B] text-[14px]">
                        {fileName}
                      </li>
                    ))}
                  </ul>
                ) : (
                  formik.touched.projectFiles &&
                  formik.errors.projectFiles && (
                    <p className="text-red-500 text-sm">
                      {formik.errors.projectFiles}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Vertical Divider */}
        <div className="hidden lg:block border-l border-gray-300 mb-4"></div>

        {/* Right Section */}
        <div className="w-[46%] bg-[#F5F5F5] rounded-xl p-5 mb-4 overflow-y-scroll">
          {formik.values.preference.items.length > 0 &&
          formik.values.preference.unitMeasurements.length > 0 &&
          formik.values.preference.unitOfAcreage.length > 0 ? (
            <>
              <div className="w-full flex items-center gap-3">
                <img src={PreferenceSetting} alt="PreferenceSetting" />
                <div className="text-[#18181B] text-xl font-medium">
                  Preferences
                </div>
              </div>
              <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
                <div className="text-[#18181B] text-lg font-medium">
                  Items of Interest
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                  {formik.values.preference.items.map((item, index) => (
                    <div
                      className="w-full flex items-center gap-2 text-[#18181B]"
                      key={index}
                    >
                      <img
                        className="w-5 h-5"
                        src={CheckCircleGreen}
                        alt="CheckCircleGreen"
                      />
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
                <div className="text-[#18181B] text-lg font-medium">
                  Units of Measurement
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                  {formik.values.preference.unitMeasurements.map(
                    (unit, index) => (
                      <div
                        className="w-full flex items-center gap-2 text-[#18181B]"
                        key={index}
                      >
                        <img
                          className="w-5 h-5"
                          src={CheckCircleGreen}
                          alt="CheckCircleGreen"
                        />
                        {unit}
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="w-full py-5 border-b border-b-[1px_solid_#71717A]">
                <div className="text-[#18181B] text-lg font-medium">
                  Acreage Units
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                  {formik.values.preference.unitOfAcreage.map((unit, index) => (
                    <div
                      className="w-full flex items-center gap-2 text-[#18181B]"
                      key={index}
                    >
                      <img
                        className="w-5 h-5"
                        src={CheckCircleGreen}
                        alt="CheckCircleGreen"
                      />
                      {unit}
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full py-5">
                <div className="text-[#18181B] text-lg font-medium">
                  Langitude and Latitude
                </div>
                <div className="w-full text-[#18181B] mt-3">
                  {formik.values.preference.longitude} -{" "}
                  {formik.values.preference.latitude}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-full grid flex-grow justify-center items-center">
              <img
                src={PreferencesIcon}
                alt="PreferencesIcon"
                className="mb-4 m-auto"
              />
              <button
                type="button"
                className="h-9 bg-black text-[14px] text-white px-4 rounded-md mb-auto"
                onClick={() => setShowPreferenceModal(true)}
              >
                Set Preferences
              </button>
            </div>
          )}
        </div>
      </form>

      {/* Checkbox Section */}
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          name="createCopy" // Adjust the name based on your form values
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          checked={formik.values.createCopy}
          className="mr-2" // Add margin to the right for spacing
        />
        <label className="font-inter text-sm font-medium leading-5 text-[#18181B]">
          Create Copy and Edit
        </label>
      </div>

      {/* Button Section */}
      <div className="flex gap-4 mt-4">
        {/* Submit Button */}
        <button
          type="submit"
          onClick={formik.handleSubmit}
          className="h-9 bg-black text-white px-4 rounded-lg"
        >
          Add Project
        </button>
        {/* Cancel Button */}
        <Link to="/">
          <button
            type="button"
            onClick={() => formik.resetForm()}
            className="h-9 bg-white text-black border border-black px-4 rounded-lg"
          >
            Cancel
          </button>
        </Link>
      </div>

      {showPreferenceModal && (
        <PreferenceModal
          isOpen={showPreferenceModal}
          onClose={() => {
            setShowPreferenceModal(false);
            formik.setFieldValue("preference", {
              items: [],
              unitMeasurements: [],
              unitOfAcreage: [],
              acp: false,
              acreage: false,
              longitude: null,
              latitude: null,
            });
          }}
          onApply={handleApplyPreference}
          formik={formik}
        />
      )}
    </div>
  );
};

export default NewProject;
